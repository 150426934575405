import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar';


const PuroliteRu = () => (
    <Layout>
      <Helmet>
          <title>Смолы Purolite</title>
      </Helmet>
      <Header 
        styleName='header'
        title='Смолы Purolite'/>
      <div className="columns">
        <div className="column content">
          <div className="content-text">ЗАО "Inžinerinė ekologija" является официальным дистрибьютором компании Purolite в Прибалтике. Мы предлагаем широкий ассортимент ионообменных материалов (катиониты, аниониты, смешанные смолы, сорбенты) для очистки и подготовки муниципальной и технологической воды, а также для специфических технологических процессов в промышленности.</div>
          <table className="table content-table-purolite">
            <thead>
            <th>Материал</th>
            <th>Предназначение</th>  
            </thead>
            <tbody>
            <tr>
              <td>Purolite C100E</td>
              <td>Умягчение питьевой воды, катионит (Na+-форма), пищевой класс</td>
            </tr>
            <tr>
              <td>Purolite C100H</td>
              <td>Сильнокислотный катионит для обессоливания, SAC (H+ -форма)</td>
            </tr>
            <tr>
              <td>Purolite C104Plus</td>
              <td>Слабокислотный катионит для умягчения, декарбонизации (удаление щелочности) и обессоливания, WAC (H+-форма)</td>
            </tr>
            <tr>
              <td>Puropack PPC100</td>
              <td>Puropack катионит – специального грансостава (650 ± 50 µm) материал для умягчение или обессоливания. Отличается большой емкостью и механической прочностью, эффективно работает на высоких скоростях и при противоточной регенерации (Na+ или H+ -форма)</td>
            </tr>
            <tr>
              <td>Purolite A520E</td>
              <td>Анионит для удаления нитратов из питьевой воды, (Cl- -форма), пищевой класс </td>
            </tr>
            <tr>
              <td>Purolite A502PS</td>
              <td>Макропористый анионит-сорбент для удаление органики, цветности, запаха, (Cl- -форма), пищевой класс</td>
            </tr>
            <tr>
              <td>Purolite A400 OH</td>
              <td>Сильноосновной анионит для обессоливания, SBA (OH- форма, гелевый)</td>
            </tr>
            <tr>
              <td>Purolite A500 OH</td>
              <td>Сильноосновной анионит для обессоливания, SBA (OH- форма, макропористый), высокая механическая и осмотическая прочность, устойчивость к органическим загрязнениям</td>
            </tr>
            <tr>
              <td>Purolite A400 MBOH IND (blue)</td>
              <td>Сильноосновной анионит с индикатором для адсорбции кислотных (HCl) паров (синий, при истощении обесцвечивается)</td>
            </tr>
            <tr>
              <td>Purolite MB400</td>
              <td>Смешанная смола для обессоливания до 0,1 µS/cm, готовая к применению смесь катионита 40% и анионита 60%</td>
            </tr>
            <tr>
              <td>Purolite MB46LT</td>
              <td>Смешанный смола для обессоливания и процессов EDM, готовая к применению смесь катионита 50% и анионита 50%</td>
            </tr>
            <tr>
              <td>Purolite S108</td>
              <td>Смола для селективного удаления бора из питьевой воды, технологических и сточных вод, пищевой класс</td>
            </tr>
            </tbody>
            <br/>
          </table>
          <div className="content-text">Предоставляем техническое сопровождение и консультации. По вопросам поставки материалов Purolite обращаться по адресу info@in-eco.biz.</div>
        </div>
        <Sidebar
        analysis='Анкеты'
        boiler='Бойлер'
        cooling='Охлаждение'
        ro='Осмос'
        broshure='IN-ECO брошура'
        certificates='Сертификаты'
        russian={true}
        />
      </div>
    </Layout>

)

export default PuroliteRu